import React from 'react';

const ApartmentCard = ({ apartment }) => {
  const formatPrice = (value) => {
    if (value >= 10000) {
      return (value / 10000).toFixed(1) + '억';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + '천만원';
    } else {
      return value.toFixed(1) + '백만원';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-2 w-full border border-gray-200">
      <h2 className="text-l font-bold mb-2 text-gray-800">{apartment.complexName}</h2>
      <p className="text-sm text-gray-600"><strong>입주시점:</strong> {apartment.입주시점}</p>
      <p className="text-sm text-gray-600"><strong>주소:</strong> {apartment.주소}</p>
      <p className="text-sm text-gray-600"><strong>평형:</strong> {apartment.평형}</p>
      <p className="text-sm text-gray-600"><strong>실거래매매가:</strong> {formatPrice(apartment.실거래매매가)}</p>
      <p className="text-sm text-gray-600"><strong>최근 거래일:</strong> {apartment.최근거래일_매매}</p>
      <p className="text-sm text-gray-600"><strong>전고점 매매:</strong> {formatPrice(apartment.전고점_매매)}</p>
      <p className="text-sm text-gray-600"><strong>전고점 매매 거래일:</strong> {apartment.전고점_매매_거래일}</p>
      <p className="text-sm text-gray-600"><strong>가격 차이:</strong> <span className="text-red-500">{formatPrice(apartment.priceDiff)}</span></p>
      <p className="text-sm text-gray-600"><strong>가격 차이 비율:</strong> <span className="text-red-500">{(apartment.priceDiffRatio * 100).toFixed(2)}%</span></p>
    </div>
  );
};

const ApartmentDashboard = () => {
  const apartments = [
    {
      complexName: '남양주별내리슈빌',
      '주소': '경기도 남양주시 별내동 914',
      '평형': 33,
      '실거래매매가': 56000,
      '전고점_매매': 78700,
      '입주시점': '20130325',
      '전고점_매매_거래일': '2021-09-06',
      '최근거래일_매매': '2024-04-01',
      priceDiff: 22700,
      priceDiffRatio: 0.2884371029224905
    },
    {
      complexName: '남양주별내유승한내들(이든힐즈)',
      '주소': '경기도 남양주시 별내동 869',
      '평형': 33,
      '실거래매매가': 52000,
      '전고점_매매': 71300,
      '입주시점': '20140124',
      '전고점_매매_거래일': '2021-09-03',
      '최근거래일_매매': '2024-05-03',
      priceDiff: 19300,
      priceDiffRatio: 0.270687237026648
    },
    {
      complexName: '별내별가람역한라비발디',
      '주소': '경기도 남양주시 별내동 808',
      '평형': 33,
      '실거래매매가': 65000,
      '전고점_매매': 89000,
      '입주시점': '20131216',
      '전고점_매매_거래일': '2021-07-02',
      '최근거래일_매매': '2023-02-03',
      priceDiff: 24000,
      priceDiffRatio: 0.2696629213483146
    },
    {
      complexName: '별내우미린스타포레',
      '주소': '경기도 남양주시 별내동 895',
      '평형': 33,
      '실거래매매가': 60000,
      '전고점_매매': 84000,
      '입주시점': '20191223',
      '전고점_매매_거래일': '2021-11-01',
      '최근거래일_매매': '2024-07-01',
      priceDiff: 24000,
      priceDiffRatio: 0.2857142857142857
    },
    {
      complexName: '별내유승한내들이노스타',
      '주소': '경기도 남양주시 별내동 893',
      '평형': 32,
      '실거래매매가': 50500,
      '전고점_매매': 71500,
      '입주시점': '20140925',
      '전고점_매매_거래일': '2021-11-01',
      '최근거래일_매매': '2023-09-04',
      priceDiff: 21000,
      priceDiffRatio: 0.2937062937062937
    },
    {
      complexName: '별내푸르지오',
      '주소': '경기도 남양주시 별내동 871',
      '평형': 33,
      '실거래매매가': 58000,
      '전고점_매매': 85000,
      '입주시점': '20151204',
      '전고점_매매_거래일': '2022-04-04',
      '최근거래일_매매': '2024-07-01',
      priceDiff: 27000,
      priceDiffRatio: 0.3176470588235294
    }
  ].sort((a, b) => b.priceDiff - a.priceDiff);

  return (
    <div className="bg-gray-100 py-2 px-5 my-5">
      <h1 className="text-2xl font-bold text-center mb-5 text-gray-800">경기도 남양주시 별내동</h1>
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {apartments.map((apartment, index) => (
            <ApartmentCard key={index} apartment={apartment} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApartmentDashboard;