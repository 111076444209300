// src/components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='flex flex-col lg:flex-row justify-between items-center'>
      <Link to="/" className='text-lg lg:text-2xl p-2'>Home</Link>
      {/* <Link to="/about" className='text-lg lg:text-2xl p-2'>About</Link> */}
      <Link to="/area" className='text-lg lg:text-2xl p-2'>SearchByArea</Link>
      <Link to="/pilot" className='text-lg lg:text-2xl p-2'>Pilot</Link>
      <Link to="/contact" className='text-lg lg:text-2xl p-2'>문의사항</Link>
    </nav>
  );
};

export default Navbar;
