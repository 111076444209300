import { useState, useEffect } from 'react';
import ResultOfApartmentContainer from '../components/ResultOfApartmentContainer';
import SearchAreaContainer from '../components/SearchAreaContainer';
import { columnOrderForAptInfo, columnOrderForAptA1B1Info } from '../constant/columnsName';

export default function SearchByArea() {
    const [isFetchingComplexes, setIsFetchingComplexes] = useState(false);
    const [complexesFetchError, setComplexesFetchError] = useState(false);
    const [isFetchingEupMyeonDongData, setIsFetchingEupMyeonDongData] = useState(false);
    const [eupMyeonDongDataFetchError, setEupMyeonDongDataFetchError] = useState(false);
    const [isFetchingSiGunGuData, setIsFetchingSiGunGuData] = useState(false);
    const [siGunGuDataFetchError, setSiGunGuDataFetchError] = useState(false);
    const [isFetchingSiDoData, setIsFetchingSiDoData] = useState(false);
    const [siDoDataFetchError, setSiDoDataFetchError] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedTown, setSelectedTown] = useState('');
    const [siDoData, setSiDoData] = useState(null);
    const [siGunGuData, setSiGunGuData] = useState(null);
    const [eupMyeonDongData, setEupMyeonDongData] = useState(null);
    const [complexes, setComplexes] = useState([]);
    const [isFetchingAptA1B1Info, setIsFetchingAptA1B1Info] = useState(false);
    const [aptA1B1ListInfo, setAptA1B1ListInfo] = useState(null);
    const [aptA1B1InfoFetchError, setAptA1B1InfoFetchError] = useState(false);
    const [isFetchingAptInfo, setIsFetchingAptInfo] = useState(false);
    const [aptInfo, setAptInfo] = useState(null);
    const [aptInfoFetchError, setAptInfoFetchError] = useState(false);
    const [formData, setFormData] = useState({"aptNumber1":"", "aptNumber2":"", "aptNumber3":""});
    
    useEffect(() => {
        fetchRegion()
    }, [])

    
    useEffect(() => {
        if (selectedRegion && selectedCity && selectedTown) {
        fetchComplexes(selectedTown)
        } else if (selectedRegion && selectedCity) {
        fetchTown(selectedCity)
        } else if (selectedRegion) {
        fetchCity(selectedRegion)
        }
    }, [selectedRegion, selectedCity, selectedTown])

    const handleRegionChange = (e) => {
        setSelectedRegion(e.target.value);
        setSelectedCity('');
        setSelectedTown('');
    };
    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
        setSelectedTown('');
    };
    const handleTownChange = (e) => {
        setSelectedTown(e.target.value);
    };
    
    
    const fetchComplexes = (cortarNo) => {
        setIsFetchingComplexes(true)
        const apiUrl = `https://apartment.codok.org/complexes/${cortarNo}`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/complexes/${cortarNo}`;
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setIsFetchingComplexes(false)
            setComplexes(data)
            setComplexesFetchError(false)
        })
        .catch((error) => {
            console.error('There was a problem with the fetchComplexes:', error);
            setComplexesFetchError(true)
            setComplexes([])
        })
    };
    
    const fetchTown = (cortarNo) => {
        setIsFetchingEupMyeonDongData(true)
        const apiUrl = `https://apartment.codok.org/regions/${cortarNo}`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/regions/${cortarNo}`;
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setIsFetchingEupMyeonDongData(false)
            setEupMyeonDongData(data)
            setEupMyeonDongDataFetchError(false)
        })
        .catch((error) => {
            console.error('There was a problem with the fetchTown:', error);
            setIsFetchingEupMyeonDongData(true)
            setEupMyeonDongData(null)
        })
    };
    
    const fetchCity = (cortarNo) => {
        setIsFetchingSiGunGuData(true)
        const apiUrl = `https://apartment.codok.org/regions/${cortarNo}`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/regions/${cortarNo}`;
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setIsFetchingSiGunGuData(false)
            setSiGunGuData(data)
            setSiGunGuDataFetchError(false)
        })
        .catch((error) => {
            console.error('There was a problem with the fetchCity:', error);
            setIsFetchingSiGunGuData(true)
            setSiGunGuData(null)
        })
    };
    
    const fetchRegion = () => {
        setIsFetchingSiDoData(true)
        const apiUrl = 'https://apartment.codok.org/regions'; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/regions`;
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setIsFetchingSiDoData(false)
            setSiDoData(data)
            setSiDoDataFetchError(false)
        })
        .catch((error) => {
            console.error('There was a problem with the fetchRegion:', error);
            setSiDoDataFetchError(true)
            setSiDoData(null)
        })
    };
    
    const getAptListA1B1Info = (apt_no, pyeongNo, isRent) => {
        setIsFetchingAptA1B1Info(true)
        const apiUrl = 'https://apartment.codok.org/apt_sale_info'; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = 'http://127.0.0.1:5001/apt_sale_info';
        // POST 요청 보내기
        fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ apt_no, pyeongNo, isRent }),
        })
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((aptA1B1Info) => {
            setIsFetchingAptA1B1Info(false)
            setAptA1B1ListInfo(aptA1B1Info)
            setAptA1B1InfoFetchError(false)
            })
            .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            setAptA1B1InfoFetchError(true)
            setAptA1B1ListInfo(null)
            })
        }

        const getAptInfo = (apt_no) => {
            setIsFetchingAptInfo(true)
            const apiUrl = 'https://apartment.codok.org/apt'; // 실제 API 엔드포인트를 넣어주세요 
            // const apiUrl = 'http://127.0.0.1:5001/apt';
            // POST 요청 보내기

            const bodyData = apt_no ? {"aptNumber1": apt_no, "aptNumber2":"", "aptNumber3":""} : formData
            fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: bodyData }),
            })
            .then((response) => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((aptInfo) => {
                setIsFetchingAptInfo(false)
                setAptInfo(aptInfo)
                setAptInfoFetchError(false)
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                setAptInfoFetchError(true)
                setAptInfo(null)
            })
        }

    return (
        <>
            <SearchAreaContainer
            selectedRegion={selectedRegion}
            siDoData={siDoData}
            handleRegionChange={handleRegionChange}
            selectedCity={selectedCity}
            siGunGuData={siGunGuData}
            handleCityChange={handleCityChange}
            selectedTown={selectedTown}
            eupMyeonDongData={eupMyeonDongData}
            handleTownChange={handleTownChange}
            complexes={complexes}
            setFormData={setFormData}
            getAptInfo={getAptInfo}
            />
            <ResultOfApartmentContainer
            isFetchingAptInfo={isFetchingAptInfo} 
            aptInfoFetchError={aptInfoFetchError}
            aptInfo={aptInfo}
            columnOrderForAptInfo={columnOrderForAptInfo}
            getAptListA1B1Info={getAptListA1B1Info}
            isFetchingAptA1B1Info={isFetchingAptA1B1Info}
            aptA1B1InfoFetchError={aptA1B1InfoFetchError}
            aptA1B1ListInfo={aptA1B1ListInfo}
            columnOrderForAptA1B1Info={columnOrderForAptA1B1Info}
            />
        </>
    )
}