export const columnOrderForAptNoInfo = [
    'complexName',
    'aptAddress',    
    'aptNo',   
]

export const columnOrderForAptInfo = [
    'complexName',
    'complexNo',
    '총세대수',
    '아프트_총_동수',
    '입주시점',
    '용적률',
    '건폐율',
    '주소',
    '평형',
    '공급면적',
    '세대수',
    '방수',
    '화장실수',
    '평당가격',
    '실거래매매가',
    '실거래전세가',
    '전매갭',
    '전세가율',
    '전고점_매매',
    '전고점_매매_거래일',
    '실거래전세가_거래일',
    '최근거래일_매매',
    '최고점대비현재가',
    '매물_리스트_확인'
];

export const columnOrderForAptA1B1Info = [
    "complexName",
    "공급면적",
    "전용면적",
    "거래방식",
    "확인매물",
    "동",
    "direction",
    "가격",
    "층",
    "동일매물"
]