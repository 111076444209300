import React from 'react';

const DropdownComponent = ({ placeHold, value, options, onChange }) => {
    return (
        <div className="mb-4">
            <select
                className="w-full bg-yellow-50 border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                value={value}
                onChange={onChange}
            >
                <option value="">{placeHold}</option>
                {options?.map((option) => (
                <option key={option.cortarNo} value={option.cortarNo}>
                    {option.cortarName}
                </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownComponent;