// components/TableComponent.jsx
import React from 'react';
import { downloadCSV } from '../util/helper';

function TableComponent({ data, columnOrder, getAptListA1B1Info }) {
  if (!data) return null;

  const filteredColumnOrder = columnOrder.filter(column => 
    !['complexName', 'complexNo', '총세대수', '아프트_총_동수', '입주시점', '용적률', '건폐율', '주소'].includes(column)
  );

  return (
    <div className='m-2 p-2 sm:w-2/3 overflow-x-auto w-full'>
      <button className="mb-3 p-2 bg-blue-400 text-white rounded" onClick={() => downloadCSV(data, filteredColumnOrder)}>
        Download CSV
      </button>
      <table className='border border-collapse border-black table-auto w-full'>
        <thead>
          <tr>
            {filteredColumnOrder.map((column, index) => (
              <th key={index} className='border border-collapse border-black text-left bg-blue-100 p-1'>
                <div className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">{column}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data[filteredColumnOrder[0]]?.map((_, rowIndex) => (
            <tr key={rowIndex}>
              {filteredColumnOrder.map((column, colIndex) => {
                if (column === "매물_리스트_확인") {
                  return (
                    <td key={colIndex} className='border border-collapse border-black text-left p-1'>
                      <div className="flex space-x-1">
                        <button className="p-1 bg-blue-400 text-white rounded-md flex-1 text-sm whitespace-nowrap" onClick={() => getAptListA1B1Info(data["complexNo"][rowIndex], data["평_no"][rowIndex], false)}>매매</button>
                        <button className="p-1 bg-orange-300 text-black rounded-md flex-1 text-sm whitespace-nowrap" onClick={() => getAptListA1B1Info(data["complexNo"][rowIndex], data["평_no"][rowIndex], true)}>전세</button>
                      </div>
                    </td>
                  );
                } else {
                  return <td key={colIndex} className='border border-collapse border-black text-left p-1 text-sm'>{data[column][rowIndex]}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;