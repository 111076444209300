// components/ApartmentInfoContainer.jsx
import React from 'react';

function ApartmentInfoContainer({ data }) {
  if (!data || data.complexName.length === 0) return null;

  const commonInfo = {
    complexName: data.complexName[0],
    complexNo: data.complexNo[0],
    totalHouseholds: data['총세대수'][0],
    totalBuildings: data['아프트_총_동수'][0],
    moveInDate: data['입주시점'][0],
    floorAreaRatio: data['용적률'][0],
    buildingCoverageRatio: data['건폐율'][0],
    address: data['주소'][0],
    dealPricesRange: `${data['매매가로우'][0]} ~ ${data['매매가하이'][0]}`,
    leasePricesRange: `${data['전세가로우'][0]} ~ ${data['전세가하이'][0]}`,
    latestDealInfo: {
      '최근매매실거래가격': `${data['최근매매실거래'][0]['formattedPrice']}`,
      '최근매매실거래일': `${data['최근매매실거래'][0]['formattedTradeYearMonth']}`,
      '최근매매실거래층': `${data['최근매매실거래'][0]['floor']}`,
      '최근매매실거래면적': `${data['최근매매실거래'][0]['representativeArea']}`,
    },
  };

  return (
    <div className="m-2 p-2 w-2/3 lg:w-1/2 bg-orange-50 rounded-lg">
      <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">
        {commonInfo.complexName}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <InfoItem label="아파트번호" value={commonInfo.complexNo} />
        <InfoItem label="총세대수" value={commonInfo.totalHouseholds} />
        <InfoItem label="총 동수" value={commonInfo.totalBuildings} />
        <InfoItem label="입주시점" value={commonInfo.moveInDate} />
        <InfoItem label="용적률" value={commonInfo.floorAreaRatio} />
        <InfoItem label="건폐율" value={commonInfo.buildingCoverageRatio} />
        <InfoItem label="주소" value={commonInfo.address} className="sm:col-span-2" />
        <InfoItem label="매매가" value={commonInfo.dealPricesRange} />
        <InfoItem label="전세가" value={commonInfo.leasePricesRange} />
        <InfoItem
          label="최근매매실거래가"
          value={commonInfo.latestDealInfo['최근매매실거래가격']}
          highlight // Pass a highlight prop for special styling
        />
        <InfoItem
          label="최근매매실거래일"
          value={commonInfo.latestDealInfo['최근매매실거래일']}
        />
        <InfoItem
          label="최근매매실거래층"
          value={commonInfo.latestDealInfo['최근매매실거래층']}
        />
        <InfoItem
          label="최근매매실거래면적"
          value={commonInfo.latestDealInfo['최근매매실거래면적']}
        />
      </div>
    </div>
  );
}

function InfoItem({ label, value, highlight, className = "" }) {
  const valueClassName = highlight ? "text-red-500" : "text-gray-600";

  return (
    <div className={`${className}`}>
      <span className={`font-semibold ${valueClassName}`}>{label}: </span>
      <span className={valueClassName}>{value}</span>
    </div>
  );
}

export default ApartmentInfoContainer;