// components/ResultOfApartmentContainer.jsx
import React from 'react';
import Spin from './Spin';
import TableComponent from './TableComponent';
import ApartmentInfoContainer from './ApartmentInfoContainer';

export default function ResultOfApartmentContainer({ 
  isFetchingAptInfo, 
  aptInfoFetchError, 
  aptInfo, 
  columnOrderForAptInfo, 
  getAptListA1B1Info, 
  isFetchingAptA1B1Info, 
  aptA1B1InfoFetchError, 
  aptA1B1ListInfo, 
  columnOrderForAptA1B1Info 
}) {
  return (
    <>
      {isFetchingAptInfo && !aptInfoFetchError ? (
        <div className='mx-2 my-1 p-2'>
          <button disabled type="button" className="text-white bg-blue-500 font-semibold text-xl rounded-lg px-5 py-2.5 text-center me-2 inline-flex items-center">
            <Spin />
            Apt Info is Loading...
          </button>
        </div>
      ) : aptInfo && (
        <>
          <ApartmentInfoContainer data={aptInfo} />
          <TableComponent columnOrder={columnOrderForAptInfo} data={aptInfo} getAptListA1B1Info={getAptListA1B1Info} />
        </>
      )}
      {aptInfoFetchError && (
        <div className='mx-2 my-1 p-2'>
          <p className='text-3xl'>Something Wrong with apt info....</p>
        </div>
      )}
      {isFetchingAptA1B1Info && !aptA1B1InfoFetchError ? (
        <div className='mx-2 my-1 p-2'>
          <button disabled type="button" className="text-white bg-blue-500 font-semibold text-xl rounded-lg px-5 py-2.5 text-center me-2 inline-flex items-center">
            <Spin />
            Apt A1B1 Info is Loading...
          </button>
        </div>
      ) : aptA1B1ListInfo && (
        <TableComponent columnOrder={columnOrderForAptA1B1Info} data={aptA1B1ListInfo} />
      )}
      {aptA1B1InfoFetchError && (
        <div className='mx-2 my-1 p-2'>
          <p className='text-2xl'>Something Wrong with A1B1_Sale info....</p>
          <p className='text-lg'>서비스 이용이 제한되었습니다. bot 프로그램 등을 이용한 비정상적인 접근이 확인되는 경우, 임시적으로 서비스 이용을 제한하고 있습니다.</p> 
          <p className='text-lg'>
            <a href='https://m.land.naver.com/error/abuse' rel="error_abuse noreferrer" className='outline py-1 px-2 bg-gray-500 text-white rounded-md hover:bg-blue-300'>
              https://m.land.naver.com/error/abuse
            </a> 가셔서 문자를 입력하시면 정상적으로 서비스를 사용하실 수 있습니다.
          </p>
        </div>
      )}
    </>
  );
}