//ComplexList.js
import React from 'react';
import ComplexCard from './ComplexCard';

const ComplexList = ({ complexes, setFormData, getAptInfo }) => {
    return (
        <div className="m-2 p-2 flex flex-col">
            <h1 className="text-2xl font-semibold mb-2">아파트 Complexes 리스트
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
            {complexes.map((complex) => (
                <ComplexCard key={complex.complexNo} complex={complex} setFormData={setFormData} getAptInfo={getAptInfo} />
                ))}
            </div>
        </div>
    );
};

export default ComplexList;
