import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ResultOfApartmentContainer from '../components/ResultOfApartmentContainer';
import Spin from '../components/Spin';
import { columnOrderForAptInfo, columnOrderForAptA1B1Info } from '../constant/columnsName';

const ApartmentDetail = () => {
  const { aptNo } = useParams();
  const [aptInfo, setAptInfo] = useState(null);
  const [aptA1B1ListInfo, setAptA1B1ListInfo] = useState(null);
  const [isFetchingAptInfo, setIsFetchingAptInfo] = useState(false);
  const [isFetchingAptA1B1Info, setIsFetchingAptA1B1Info] = useState(false);
  const [aptInfoFetchError, setAptInfoFetchError] = useState(false);
  const [aptA1B1InfoFetchError, setAptA1B1InfoFetchError] = useState(false);

  useEffect(() => {
    if (aptNo) {
      getAptInfo(aptNo);
    }
  }, [aptNo]);

  const getAptInfo = (apt_no) => {
    setIsFetchingAptInfo(true);
    const apiUrl = 'https://apartment.codok.org/apt';
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: { aptNumber1: apt_no, aptNumber2: "", aptNumber3: "" } }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((aptInfo) => {
        setIsFetchingAptInfo(false);
        setAptInfo(aptInfo);
        setAptInfoFetchError(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setAptInfoFetchError(true);
        setAptInfo(null);
        setIsFetchingAptInfo(false);
      });
  };

  const getAptListA1B1Info = (apt_no, pyeongNo, isRent) => {
    setIsFetchingAptA1B1Info(true);
    const apiUrl = 'https://apartment.codok.org/apt_sale_info';
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ apt_no, pyeongNo, isRent }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((aptA1B1Info) => {
        setIsFetchingAptA1B1Info(false);
        setAptA1B1ListInfo(aptA1B1Info);
        setAptA1B1InfoFetchError(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setAptA1B1InfoFetchError(true);
        setAptA1B1ListInfo(null);
        setIsFetchingAptA1B1Info(false);
      });
  };

  if (isFetchingAptInfo) {
    return (
      <div className='m-2 p-2'>
        <button disabled type="button" className="text-white bg-blue-500 font-semibold text-xl rounded-lg px-5 py-2.5 text-center me-2 inline-flex items-center">
          <Spin />
          아파트 정보 불러오는 중...
        </button>
      </div>
    );
  }

  if (aptInfoFetchError) {
    return (
      <div className='m-2 p-2 text-center'>
        <p className='text-2xl mb-4'>아파트 정보를 찾을 수 없습니다. 올바른 아파트 번호인지 확인해주세요.</p>
        <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          홈으로 돌아가기
        </Link>
      </div>
    );
  }

  return (
    <div className='mx-2 my-1 p-2'>
      <ResultOfApartmentContainer 
        isFetchingAptInfo={isFetchingAptInfo}
        aptInfoFetchError={aptInfoFetchError}
        aptInfo={aptInfo}
        columnOrderForAptInfo={columnOrderForAptInfo}
        getAptListA1B1Info={getAptListA1B1Info}
        isFetchingAptA1B1Info={isFetchingAptA1B1Info}
        aptA1B1InfoFetchError={aptA1B1InfoFetchError}
        aptA1B1ListInfo={aptA1B1ListInfo}
        columnOrderForAptA1B1Info={columnOrderForAptA1B1Info}
      />
    </div>
  );
};

export default ApartmentDetail;