import React, { useState, useEffect } from 'react';
import DropdownComponent from '../components/DropdownComponent';
import ApartmentDashboard from '../components/ApartmentCard';

export default function Diff() {
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedTown, setSelectedTown] = useState('');
    const [selectedRegionName, setSelectedRegionName] = useState('');
    const [selectedCityName, setSelectedCityName] = useState('');
    const [selectedTownName, setSelectedTownName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [siDoData, setSiDoData] = useState(null);
    const [isFetchingSiDoData, setIsFetchingSiDoData] = useState(false);
    const [siDoDataFetchError, setSiDoDataFetchError] = useState(false);
    const [siGunGuData, setSiGunGuData] = useState(null);
    const [isFetchingSiGunGuData, setIsFetchingSiGunGuData] = useState(false);
    const [siGunGuDataFetchError, setSiGunGuDataFetchError] = useState(false);
    const [eupMyeonDongData, setEupMyeonDongData] = useState(null);
    const [isFetchingEupMyeonDongData, setIsFetchingEupMyeonDongData] = useState(false);
    const [eupMyeonDongDataFetchError, setEupMyeonDongDataFetchError] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null);

    useEffect(() => {
        fetchRegion()
    }, [])

    useEffect(() => {
        if (selectedRegion && selectedCity) {
            fetchTown(selectedCity)
        } else if (selectedRegion) {
            fetchCity(selectedRegion)
        }
    }, [selectedRegion, selectedCity, selectedTown])

    useEffect(() => {
        let timer;
        if (submissionStatus === '제출이 완료되었습니다.') {
            timer = setTimeout(() => {
                setSubmissionStatus(null);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [submissionStatus]);

    const handleSubmit = async () => {
        setSubmissionStatus('처리 중...');
        
        const webhookUrl = 'https://hook.us1.make.com/f5ysej0mk8j79i5lcpumaku67d7e58k5';
        
        const data = {
            name,
            email,
            region: selectedRegion,
            city: selectedCity,
            town: selectedTown,
            regionName: selectedRegionName,
            cityName: selectedCityName,
            townName: selectedTownName
        };
        try {
            const response = await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setSubmissionStatus('제출이 완료되었습니다.');
                // 성공적인 제출 후 폼 초기화
                setSelectedRegion('');
                setSelectedCity('');
                setSelectedTown('');
                setSelectedRegionName('');
                setSelectedCityName('');
                setSelectedTownName('');
                setName('');
                setEmail('');
            } else {
                throw new Error('서버 응답이 올바르지 않습니다.');
            }
        } catch (error) {
            console.error('제출 중 오류 발생:', error);
            setSubmissionStatus('제출 중 오류가 발생했습니다. 나중에 다시 시도해 주세요.');
        }
    }

    const handleRegionChange = (e) => {
        const selectedOption = siDoData.find(option => option.cortarNo === e.target.value);
        setSelectedRegion(e.target.value);
        setSelectedRegionName(selectedOption ? selectedOption.cortarName : '');
        setSelectedCity('');
        setSelectedCityName('');
        setSelectedTown('');
        setSelectedTownName('');
    };

    const handleCityChange = (e) => {
        const selectedOption = siGunGuData.find(option => option.cortarNo === e.target.value);
        setSelectedCity(e.target.value);
        setSelectedCityName(selectedOption ? selectedOption.cortarName : '');
        setSelectedTown('');
        setSelectedTownName('');
    };

    const handleTownChange = (e) => {
        const selectedOption = eupMyeonDongData.find(option => option.cortarNo === e.target.value);
        setSelectedTown(e.target.value);
        setSelectedTownName(selectedOption ? selectedOption.cortarName : '');
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const fetchTown = (cortarNo) => {
        setIsFetchingEupMyeonDongData(true)
        const apiUrl = `https://apartment.codok.org/regions/${cortarNo}`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/regions/${cortarNo}`;
        fetch(apiUrl)
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then(data => {
            setIsFetchingEupMyeonDongData(false)
            setEupMyeonDongData(data)
            setEupMyeonDongDataFetchError(false)
            })
            .catch((error) => {
            console.error('There was a problem with the fetchTown:', error);
            setIsFetchingEupMyeonDongData(true)
            setEupMyeonDongData(null)
            })
        };


        const fetchCity = (cortarNo) => {
        setIsFetchingSiGunGuData(true)
        const apiUrl = `https://apartment.codok.org/regions/${cortarNo}`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/regions/${cortarNo}`;
        fetch(apiUrl)
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then(data => {
            setIsFetchingSiGunGuData(false)
            setSiGunGuData(data)
            setSiGunGuDataFetchError(false)
            })
            .catch((error) => {
            console.error('There was a problem with the fetchCity:', error);
            setIsFetchingSiGunGuData(true)
            setSiGunGuData(null)
            })
        };

        const fetchRegion = () => {
        setIsFetchingSiDoData(true)
        const apiUrl = 'https://apartment.codok.org/regions'; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/regions;
        fetch(apiUrl)
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then(data => {
            setIsFetchingSiDoData(false)
            setSiDoData(data)
            setSiDoDataFetchError(false)
            })
            .catch((error) => {
            console.error('There was a problem with the fetchRegion:', error);
            setSiDoDataFetchError(true)
            setSiDoData(null)
            })
        };
        
        return (
            <div className="h-screen">
                <main className="flex-grow flex flex-col items-center justify-evenly h-full pt-10 pb-64">
                    <h2 className="text-5xl font-bold text-center">전고점 매매가 - 최근 실거래가</h2>
                    <p className="text-xl text-center mt-5">
                        원하시는 지역을 선택하시면 해당 지역의 아파트 정보 (20평 이상 38평 이하)를 모두 취합하여<br />
                        전고점 매매 금액과 최근 거래된 실거래 금액의 차이가 큰 순으로 정열해서 아래와 같이 등록하신 이메일로 보내 드리겠습니다.
                    </p>
    
                    <ApartmentDashboard />

                    <div className="w-full max-w-4xl grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <h3 className="text-lg font-semibold mb-1">지역 선택</h3>
                            <DropdownComponent
                                placeHold="지역을 선택하세요"
                                value={selectedRegion}
                                options={siDoData}
                                onChange={handleRegionChange}
                            />
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-1">시/군/구 선택</h3>
                            <DropdownComponent
                                placeHold="시/군/구를 선택하세요"
                                value={selectedCity}
                                options={siGunGuData}
                                onChange={handleCityChange}
                            />
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-1">읍/면/동 선택</h3>
                            <DropdownComponent
                                placeHold="읍/면/동을 선택하세요"
                                value={selectedTown}
                                options={eupMyeonDongData}
                                onChange={handleTownChange}
                            />
                        </div>
                    </div>
    
                    <div className="w-full max-w-2xl space-y-2">
                        <div className="w-full flex justify-between"> 
                            <input
                                type="text"
                                className="w-1/3 border rounded p-2 mr-2"
                                placeholder="이름"
                                value={name}
                                onChange={handleNameChange}
                            />
                            <input
                                type="email"
                                className="w-2/3 border rounded p-2"
                                placeholder="이메일"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        <p className="p-2 text-sm text-gray-400">이메일은 아파트 정보 전달 목적 외 활용하지 않습니다.</p>
                        <button
                            onClick={handleSubmit}
                            className="w-full bg-blue-500 text-white rounded px-4 py-2"
                        >
                            정보 제출하기
                        </button>
                        {submissionStatus && (
                            <p className="text-center text-green-600">{submissionStatus}</p>
                        )}
                    </div>
                </main>
            </div>
        );
    }