// CSV 파일 생성 함수
export const convertArrayOfObjectsToCSV = (data, columnOrder) => {
    const headers = columnOrder.join(',');

    // CSV 행 데이터 준비
    const rows = data.complexName?.map((_, index) => {
        return columnOrder.map(column => {
            if (column !== '매물_리스트_확인') {
                let cell = data[column][index];
                // 데이터에 콤마나 쌍따옴표, 줄바꿈이 포함되어 있으면 쌍따옴표로 묶어주고, 쌍따옴표를 이스케이프 처리합니다.
                if ((typeof cell !== 'number') && cell && (cell.includes(',') || cell.includes('\n') || cell.includes('"'))) {
                    cell = `"${cell.replace(/"/g, '""')}"`;  // 쌍따옴표 이스케이프 처리
                }
                return cell;
            }
        }).join(',');
    });
    return [headers, ...rows].join('\n');
};

// CSV 파일 다운로드 함수
export const downloadCSV = (data, columnOrder) => {
    const csvString = convertArrayOfObjectsToCSV(data, columnOrder);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'apartments.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};