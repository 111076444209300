import React from 'react';
import { Link } from 'react-router-dom';

const SearchByRegion = ({ setAptInfo, setAptA1B1ListInfo }) => {
    const handleClick = () => {
        setAptInfo(null)
        setAptA1B1ListInfo(null)
    }

    return (
        <div className='m-2 p-2 w-2/3 lg:w-1/2'>
            <p className="text-base sm:text-lg md:text-xl font-semibold mb-2 sm:mb-3">
                버튼을 누르시면 지역을 선택해서 apt no를 확인 하실 수 있습니다.
            </p>
                <Link to="/area">
                    <button
                        onClick={handleClick}
                        className='px-3 sm:px-4 md:px-5 py-2 bg-blue-400 text-white rounded-md w-full sm:w-2/3 md:w-1/2 text-sm sm:text-base'
                    >
                        아파트 찾기
                    </button>
                </Link>
        </div>
    );
};

export default SearchByRegion;