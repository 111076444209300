import React from 'react';

export default function SearchForm({ handleSubmit, formData, handleInputChange }) {
    const isAllFieldsEmpty = !formData.aptNumber1 && !formData.aptNumber2 && !formData.aptNumber3;
    // const isFirstFieldEmpty = !formData.aptNumber1;
    const baseInputClass = 'outline-none border border-gray-300 p-2 rounded-md w-full focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-150 ease-in-out';
    // const disabledInputClass = 'bg-gray-100 cursor-not-allowed';

    return (
        <div className='m-2 p-2 w-2/3'>
            <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row items-center space-y-2 lg:space-y-0 lg:space-x-2">
                <div className="w-full lg:w-1/4">
                    <input
                        type="text"
                        placeholder="Please enter apt1_no"
                        value={formData.aptNumber1}
                        onChange={handleInputChange}
                        name="aptNumber1"
                        className={`${baseInputClass}`}
                    />
                </div>
                <div className="w-full lg:w-1/4">
                    <button 
                        type="submit"
                        disabled={isAllFieldsEmpty}
                        className='w-full p-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed transition duration-150 ease-in-out hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300'
                    >
                        Submit
                    </button>
                </div>
                {/* <div className="w-full lg:w-1/4">
                    <input
                        type="text"
                        placeholder="Please enter apt2_no"
                        value={formData.aptNumber2}
                        onChange={handleInputChange}
                        name="aptNumber2"
                        disabled={isFirstFieldEmpty}
                        className={`${baseInputClass} ${isFirstFieldEmpty ? disabledInputClass : ''}`}
                    />
                </div>
                <div className="w-full lg:w-1/4">
                    <input
                        type="text"
                        placeholder="Please enter apt3_no"
                        value={formData.aptNumber3}
                        onChange={handleInputChange}
                        name="aptNumber3"
                        disabled={!formData.aptNumber2}
                        className={`${baseInputClass} ${!formData.aptNumber2 ? disabledInputClass : ''}`}
                    />
                </div> */}

            </form>
        </div>
    );
}